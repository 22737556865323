<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { Erreur } from "../../../helpers/error";
import Swal from "sweetalert2";
var moment = require("moment");
moment.locale("fr");

import { Api } from "../../../helpers";
/**
 * Projects-overview component
 */
export default {
  page: {
    title: "Projects Overview",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, },
  data() {
    return {
      idDailyMotion: "",
      image: "",
      sectionsProgramme: [],
      listFav: false,
      listLike: false,
      moment: moment,
      likesList: [],
      commentList: [],
      favList: [],
      privateId: "",
      description: "",
      titre: "",
      dateAdd: "",
      duree: "",
      annee: "",
      categorie: "",
      tags: [],
      vue: "",
      title: "Details Video",
      items: [
        {
          text: "Vidéos",
          href: "/projects/videos",
        },
        {
          text: "Détails",
          active: true,
        },
      ],
    };
  },
  methods: {
    showListFav() {
      this.listFav = true;
    },

    showListLikes() {
      this.listLike = true;
    },

    showUpdateVideo() {
      this.updateVideoModal = true;
    },

    showUpdateModal() {
      this.updateModal = true;
      this.updateMode = "";
    },

    showUpdateMetadonnees() {
      this.updateModal = true;
      this.updateMode = "metadonnees";
    },

    getDateAndTimeFromDate(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      const dateStr = `${year}/${month}/${day}`;

      return { date: dateStr };
    },

    updateImageVideo() {
      this.titre = "Importation de la vidéo";

      this.process = true;
      this.importModalRef = false;

      // if (!this.programmeId) {
      Api.patch(
        `/streamvod/rest/videos/update-thumbnail/${this.$route.params.id}?id_dailymotion=${this.idDailyMotion}`
      )
        .then(() => {
          Swal.fire("Success!", "Image de Vignette mise à jour", "success");
        })
        .catch((error) => {
          this.process = false;
          this.progress = 0;
          Erreur.gestionErreur(error.message);
        });
    },
  },

  mounted() {
    Api.get("/streamvodv2/api/v2/video/get/" + this.$route.params.uiid)
      .then((result) => {
        this.video = result.data.content;
        console.log(this.video);
        this.idDailyMotion = this.video.idDailymotion;
        // this.$refs.videoPlayer.src = this.video.url;
        this.privateId = this.video.privateId;
        this.likesList = this.video.likes;
        this.commentList = this.video.comments;
        this.favList = this.video.favorites;
        this.description = this.video.description;
        this.titre = this.video.title;

        const date = this.getDateAndTimeFromDate(
          new Date(this.video.releaseDate)
        );

        this.dateAdd = date.date;
        this.duree = this.video.duration;
        this.categorie = this.video.categories;
        this.tags = this.video.tags;
        this.vue = this.video.nbViews;
      })
      .catch((error) => {
        // If there's an error
        this.process = false; // Set the process variable to false
        Erreur.gestionErreur(error.message); // Call the error handling function
      });

    // this.sectionsProgramme = this.video.programme.sections
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <button type="submit" @click="updateImageVideo" class="btn c2play-primary">
      MAJ de la vignette
    </button>
    <br /><br /> -->
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <!-- <div
              style="
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
              "
              itemscope
              itemtype="https://schema.org/VideoObject"
            >
              <video
                ref="videoPlayer"
                src=""
                controls
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  overflow: hidden;
                "
                width="720"
                height="480"
              ></video>
            </div> -->

            <div style="height: 400px; width: 100%; border: 1px solid #ffffff">
              <iframe
                style="width: 100%"
                frameborder="0"
                height="400"
                :src="
                  'https://geo.dailymotion.com/player/xpnus.html?video=' +
                  idDailyMotion
                "
                allowfullscreen
                allow="autoplay; fullscreen; picture-in-picture; web-share"
              >
              </iframe>
            </div>
            <div
              class="c2play-border-primary"
              style="
                border-radius: 5px;
                padding-right: 10px;
                padding-left: 10px;
                margin-top: 15px;
                margin-bottom: 20px;
              "
            >
              <h2 class="mt-4">{{ titre }}</h2>
              <h5 style="font-style: italic">{{ description }}</h5>
            </div>

            <div class="row">
              <div class="col-md-6">
                <!-- <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" /> -->
                <div class="c2play-border-primary card mini-stats-wid">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <h5 class="text-muted fw-medium mb-2">Likes</h5>

                        {{ likesList.length }}
                      </div>

                      <div
                        class="avatar-sm align-self-center mini-stat-icon rounded-circle"
                      >
                        <span class="c2play-primary avatar-title">
                          <i class="mdi mdi-thumb-up font-size-30"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end card-body -->
                </div>
              </div>

              <div class="col-md-6">
                <!-- <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" /> -->
                <div class="c2play-border-primary card mini-stats-wid">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <h5 class="text-muted fw-medium mb-2">Favories</h5>

                        {{ favList.length }}
                      </div>

                      <div
                        class="avatar-sm align-self-center mini-stat-icon rounded-circle"
                      >
                        <span class="c2play-primary avatar-title">
                          <i class="mdi mdi-bookmark font-size-30"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end card-body -->
                </div>
              </div>

              <div class="col-md-6">
                <!-- <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" /> -->
                <div class="c2play-border-primary card mini-stats-wid">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <h5 class="text-muted fw-medium mb-2">Nombre de vue</h5>

                        {{ vue }}
                      </div>

                      <div
                        class="avatar-sm align-self-center mini-stat-icon rounded-circle"
                      >
                        <span class="c2play-primary avatar-title">
                          <i class="mdi mdi mdi-timer font-size-30"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end card-body -->
                </div>
              </div>

              <div class="col-md-6">
                <!-- <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" /> -->
                <div class="c2play-border-primary card mini-stats-wid">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <h5 class="text-muted fw-medium mb-2">Durée</h5>

                        {{ duree }}
                      </div>

                      <div
                        class="avatar-sm align-self-center mini-stat-icon rounded-circle"
                      >
                        <span class="c2play-primary avatar-title">
                          <i class="mdi mdi mdi-timer font-size-30"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end card-body -->
                </div>
              </div>

              <div class="col-md-6">
                <!-- <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" /> -->
                <div class="c2play-border-primary card mini-stats-wid">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <h5 class="text-muted fw-medium mb-2">Catégorie</h5>

                        <div
                          class="display"
                          style="
                            width: 200px;
                            ::-webkit-scrollbar {
                              display: none;
                            }
                          "
                        >
                          <div
                            v-for="categ in categorie"
                            :key="categ.id.id"
                            class="tags-input_tag c2play-primary"
                          >
                            {{ categ.name }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="avatar-sm align-self-center mini-stat-icon rounded-circle"
                      >
                        <span class="c2play-primary avatar-title">
                          <i class="mdi mdi mdi-animation font-size-30"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end card-body -->
                </div>
              </div>
              <div class="col-md-6">
                <!-- <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" /> -->
                <div class="c2play-border-primary card mini-stats-wid">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <h5 class="text-muted fw-medium mb-2">Tags</h5>

                        <div
                          class="display"
                          style="
                            width: 200px;
                            ::-webkit-scrollbar {
                              display: none;
                            }
                          "
                        >
                          <div
                            v-for="tag in tags"
                            :key="tag.id"
                            class="tags-input_tag c2play-primary"
                          >
                            {{ tag.name }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="avatar-sm align-self-center mini-stat-icon rounded-circle"
                      >
                        <span class="c2play-primary avatar-title">
                          <i
                            class="mdi mdi-checkbox-multiple-marked font-size-30"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end card-body -->
                </div>
              </div>

              
              <div class="col-md-6">
                <!-- <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" /> -->
                <div class="c2play-border-primary card mini-stats-wid">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <h5 class="text-muted fw-medium mb-2">
                          Date de publication
                        </h5>
                        {{ dateAdd }}
                      </div>

                      <div
                        class="avatar-sm align-self-center mini-stat-icon rounded-circle"
                      >
                        <span class="c2play-primary avatar-title">
                          <i
                            class="mdi mdi mdi-calendar-clock font-size-30"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end card-body -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->

      <div class="col-lg-4">
        <div class="card" style="max-height: 1080px">
          <div class="card-body">
            <h4 class="mb-4">Commentaires {{ commentList.length }}</h4>

            <div
              class="d-flex mb-4"
              v-for="(commentaire, index) in commentList.slice().reverse()"
              :key="index"
            >
              <div class="avatar-sm me-4" style="min-width: 50px">
                <span
                  class="c2play-bg-secondary avatar-title rounded-circle bg-soft text-primary font-size-24"
                >
                  <img
                    v-if="commentaire.user_photo"
                    class="media-object rounded-circle avatar-xs"
                    alt
                    :src="commentaire.user_photo"
                  />
                  <img
                    v-else
                    class="media-object rounded-circle avatar-xs"
                    alt
                    src="@/assets/logos/images/profile.png"
                  />
                </span>
              </div>
              <div class="flex-grow-1">
                <h5 class="font-size-13 mb-1">{{ commentaire.user }}</h5>
                <p class="text-muted mb-1">{{ commentaire.content }}</p>
              </div>
              <div class="ms-3">
                {{ moment(commentaire.createdAt).fromNow() }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-4"></div>

      <b-modal
        v-model="listLike"
        id="modal-ml"
        size="ml"
        title="Détails de la vidéo"
        title-class="font-18"
        hide-footer
      >
        <div class="card">
          <div class="comment-block card">
            <div class="card-body">
              <h4 class="card-title mb-4">
                {{ $t("pages.overviewVideos.likes") }}
                {{ this.likesList.length }}
              </h4>

              <div id="overview-chart" class="apex-charts" dir="ltr">
                <table
                  class="table table-nowrap table-centered table-hover mb-0 align-middle"
                >
                  <tbody>
                    <tr v-for="(like, index) in likesList" :key="index">
                      <td style="width: 45px">
                        <div class="avatar-sm">
                          <span
                            class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24"
                          >
                            <img
                              class="media-object rounded-circle avatar-xs"
                              alt
                              src="@/assets/logos/images/profile.png"
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <h5 class="font-size-14 mb-1">
                          {{ like }}
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        v-model="listFav"
        id="modal-ml"
        size="ml"
        title="Détails de la vidéo"
        title-class="font-18"
        hide-footer
      >
        <div class="card">
          <div class="comment-block card">
            <div class="card-body">
              <h4 class="card-title mb-4">
                {{ $t("pages.overviewVideos.fav") }} {{ favList.length }}
              </h4>
              <div class="table-responsive">
                <table
                  class="table table-nowrap table-centered table-hover mb-0 align-middle"
                >
                  <tbody>
                    <tr v-for="(fav, index) in favList" :key="index">
                      <td style="width: 45px">
                        <div class="avatar-sm">
                          <span
                            class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24"
                          >
                            <img
                              class="media-object rounded-circle avatar-xs"
                              alt
                              src="@/assets/logos/images/profile.png"
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <h5 class="font-size-14 mb-1">
                          {{ fav }}
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div
          v-for="section in sectionsProgramme"
          :key="section.id"
          class="card pt-4"
          style="padding-left: 5%; padding-right: 5%"
        >
          <div class="row">
            <h2 class="col-lg-11">{{ section.titre }}</h2>
            <div class="col-lg-1">
              <p class="card-title-desc">
                <b-dropdown
                  class="card-drop"
                  variant="white"
                  menu-class="dropdown-menu-end"
                  right
                  toggle-class="p-0"
                >
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>
                  <!-- <b-dropdown-item :href="'/projects/overview/' + section.id"
                                        >{{ $t("pages.sections.actions.details") }}</b-dropdown-item
                                    > -->
                  <b-dropdown-item @click="selectOneSection(section.id)">{{
                    $t("pages.sections.actions.modifier")
                  }}</b-dropdown-item>
                  <b-dropdown-item @click="confirm(section.id)">{{
                    $t("pages.sections.actions.supprimer")
                  }}</b-dropdown-item>
                </b-dropdown>
              </p>
            </div>
          </div>

          <div class="display">
            <VideosCardVue
              v-for="video in section.videos.slice().reverse()"
              :key="video.id"
              :image="video.image.url"
              :title="video.title"
              :vues="video.nbVues"
              :likes="video.likedUsers.length"
              :time="video.duration"
              :years="video.years"
              :id="video.id"
              :initialTags="video.tags"
            ></VideosCardVue>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
